import { computed, reactive } from 'vue';
import { useModal } from '@/modules/productModal';

export const state = reactive({
  productId: null,
  activeTab: 'purchase',
});

const { open, productId: productIdRef } = useModal();
export function openProductModal(productId) {
  open(productId);
}

export const productId = productIdRef;

export function useProductState() {
  const productId = computed({
    get: () => state.productId,
    set: (id) => (state.productId = id),
  });
  const activeTab = computed({
    get: () => state.activeTab,
    set: (tab) => (state.activeTab = tab),
  });
  return { productId, activeTab };
}
