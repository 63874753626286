<template>
  <Modal
    :visible="dialogVisible"
    :title="$t('document.documentRecording.addProductModal.title')"
    width="25rem"
    :top="'14vh'"
    :submit-loading="submitLoading"
    @on-close="onClose"
    @on-submit="submitForm"
  >
    <el-form v-if="dialogVisible" ref="form" :model="model" :rules="rules" label-width="150px" class="add-product-form">
      <el-form-item prop="name">
        <div class="input-field">
          <p class="input-label required-field">{{ $t('document.documentRecording.addProductModal.name') }}</p>
          <el-input v-model="model.name"></el-input>
        </div>
        <div v-if="showSimilarItems" class="similar-items-modal">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>{{ $t('document.documentRecording.addProductModal.similarItems.sku') }}</th>
                <th>{{ $t('document.documentRecording.addProductModal.similarItems.name') }}</th>
                <th>{{ $t('document.documentRecording.addProductModal.similarItems.score') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="{ item, score } in similarItems" :key="item._id">
                <td>{{ item.sku }}</td>
                <td>{{ item.name }}</td>
                <td>{{ score.toFixed(3) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form-item>

      <el-form-item prop="sku">
        <div class="input-field">
          <p class="input-label">{{ $t('document.documentRecording.addProductModal.sku') }}</p>
          <el-input v-model="model.sku" class="direction-ltr"></el-input>
        </div>
      </el-form-item>

      <el-form-item prop="gtin">
        <div class="input-field">
          <p class="input-label">{{ $t('document.documentRecording.addProductModal.gtin') }}</p>
          <el-input v-model="model.gtin" class="direction-ltr"></el-input>
        </div>
      </el-form-item>

      <el-form-item prop="catalogId">
        <div class="input-field gap-2">
          <div class="d-flex gap-1">
            <p class="required-field input-label">
              {{ $t('document.documentRecording.addProductModal.belongsToCatalog') }}
            </p>
            <el-tooltip
              placement="top"
              popper-class="catalog-tooltip"
              :content="$t('document.documentRecording.addProductModal.catalogTooltip')"
            >
              <div class="tooltip-trigger">
                <QuestionMarkNoFillIcon :size="16" fill="#94989F" />
              </div>
            </el-tooltip>
          </div>
          <el-radio-group v-model="model.hasCatalog">
            <el-radio :label="true">{{ $t('document.documentRecording.addProductModal.yes') }}</el-radio>
            <el-radio :label="false">{{ $t('document.documentRecording.addProductModal.no') }}</el-radio>
          </el-radio-group>
          <el-select v-model="model.catalogId" :disabled="!model.hasCatalog" popper-class="catalog-select">
            <el-option
              v-for="catalog in catalogs"
              :key="catalog.id"
              :label="catalog.name"
              :value="catalog.id"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
  </Modal>
</template>
<script>
import Fuse from 'fuse.js';
import { filter, pipe, not, isNil } from 'ramda';
import { ref, computed, watch, getCurrentInstance } from 'vue';

import { QuestionMarkNoFillIcon } from '@/assets/icons';
import Modal from '@/modules/catalog/components/Modal.vue';
import { useCreateProduct, useProductsNew } from '@/modules/product/compositions';
import { useCatalogs } from '@/modules/catalog/compositions/useCatalog';
import { useCreateProductInCatalog } from '@/modules/catalog/compositions/useCreateProductInCatalog';

const EMPTY_MODEL = {
  name: '',
  sku: '',
  gtin: '',
  hasCatalog: null,
  catalogId: null,
};

const filterNil = filter(pipe(isNil, not));

export default {
  name: 'AddProductModal',
  components: { Modal, QuestionMarkNoFillIcon },
  props: {
    visible: { type: Boolean, default: false },
    supplierId: { type: String, default: null },
    itemId: { type: String, default: null },
    defaults: { type: Object, default: null },
  },
  emits: ['update:visible', 'submit'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const form = ref(null);
    const attemptedSubmit = ref(false);
    const model = ref({ ...EMPTY_MODEL });
    const createdProduct = ref(null);

    const validateCatalog = (_, __, callback) => {
      if (
        model.value.hasCatalog === null ||
        (model.value.hasCatalog && !model.value.catalogId && attemptedSubmit.value)
      ) {
        callback(new Error(root.$t('document.documentRecording.addProductModal.errors.catalogNotChosen')));
      } else {
        callback();
      }
    };
    const rules = {
      name: { required: true, message: root.$t('document.documentRecording.addProductModal.errors.name') },
      catalogId: { validator: validateCatalog, trigger: 'change' },
      hasCatalog: { validator: validateCatalog, trigger: 'change' },
    };

    const { products } = useProductsNew(computed(() => ({ businessId: props.supplierId })));
    const { createProduct, onDone: onCreateProductDone, loading: creatingProductLoading } = useCreateProduct();
    const {
      createProductInCatalog,
      onDone: onCreateProductInCatalogDone,
      loading: creatingProductInCatalogLoading,
    } = useCreateProductInCatalog();

    const { catalogs } = useCatalogs(computed(() => ({ businessId: props.supplierId, first: 100, after: 0 })));

    const dialogVisible = computed({
      get: () => props.visible,
      set: (value) => emit('update:visible', value),
    });
    const itemStore = computed(() => {
      const fuseOptions = {
        shouldSort: true,
        includeScore: true,
        threshold: 0.2,
        keys: ['name'],
      };
      return new Fuse(products.value, fuseOptions);
    });

    const similarItems = computed(() => {
      if (model.value.name.length < 3) return [];
      if (!itemStore.value) return [];
      return itemStore.value.search(model.value.name);
    });
    const showSimilarItems = computed(() => Boolean(similarItems.value.length));

    const submitLoading = computed(() => creatingProductLoading.value || creatingProductInCatalogLoading.value);

    watch(
      () => props.defaults,
      (value) => {
        Object.assign(model.value, EMPTY_MODEL, { sku: value.code, ...value });
      },
      { deep: true, immediate: true }
    );

    const onClose = () => {
      model.value = { ...EMPTY_MODEL };
      dialogVisible.value = false;
      attemptedSubmit.value = false;
      createdProduct.value = null;
    };

    onCreateProductDone(({ data: { productCreateNew: newProduct } }) => {
      if (model.value.hasCatalog && model.value.catalogId) {
        createProductInCatalog({ id: model.value.catalogId, params: { productId: newProduct.id } });
        createdProduct.value = filterNil(newProduct);
      } else {
        root.$message.success(root.$t('document.documentRecording.addProductModal.createdNewProduct'));
        emit('submit', filterNil(newProduct));
        onClose();
      }
    });

    onCreateProductInCatalogDone(() => {
      root.$message.success(root.$t('document.documentRecording.addProductModal.createdNewProductWithCatalog'));
      emit('submit', createdProduct.value);
      onClose();
    });

    const submitForm = () => {
      attemptedSubmit.value = true;
      form.value.validate((valid) => {
        if (valid) {
          createProduct({
            params: {
              businessId: props.supplierId,
              name: model.value.name,
              ...(model.value.sku ? { sku: model.value.sku } : {}),
              ...(model.value.gtin ? { gtin: model.value.gtin } : {}),
            },
          });
        }
      });
    };

    return {
      form,
      rules,
      model,
      catalogs,
      itemStore,
      similarItems,
      dialogVisible,
      submitLoading,
      showSimilarItems,
      onClose,
      submitForm,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.similar-items-modal {
  top: 38px;
  width: max-content;
  left: calc(100% + 20px);
  position: absolute;
  background: #ffffff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}

::v-deep .direction-ltr .el-input__inner {
  direction: ltr;
}

.add-product-form {
  color: $typography-primary;
  ::v-deep {
    .el-form-item__content {
      width: 95%;
      line-height: 1rem;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: $typography-primary;
    }
  }
}

.input-field {
  display: flex;
  flex-direction: column;

  .input-label {
    height: 1.25rem;
  }

  .required-field {
    &::after {
      color: #e52044;
      content: '*';
    }
  }
}

.tooltip-trigger {
  cursor: pointer;
}
</style>
<style lang="scss">
.catalog-select {
  div.el-scrollbar__wrap {
    overflow: hidden;
  }
}
.catalog-tooltip {
  &.el-tooltip__popper {
    max-width: 15.5rem;
    padding: 5px;
  }
}
</style>
